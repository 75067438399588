import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Preload from './controls/preload.jsx';
import Env from './views/env.jsx';
import jQuery from 'jquery';
import moment from 'moment';
import '../node_modules/moment/locale/es-do';

import './index.css';

import Login from './views/login.jsx';
import Recovery from './views/recovery.jsx';
import Dashboard from './views/dashboardv2.jsx';
import Vehicle from './views/vehicle.jsx';
import HistoryMap from './views/historymap.jsx';
import Account from './views/account.jsx';
// import Bills from './views/bills.jsx';
// import Support from './views/support.jsx';
// import AccountLog from './views/records.jsx';
import Notifications from './views/notifications';
// import Config from './views/config.jsx';
import GlobalMap from './views/globalmap.jsx';
import Alerts from './views/alertsconfig.jsx';
import Reports from './views/reports.jsx';
import Zonas from './views/zonas.jsx';

// import { unregister } from './registerServiceWorker';

// Configuraciones globales

jQuery.ajaxSetup({
    crossDomain: true,
    xhrFields: {
        withCredentials: true
    }
});

moment.locale('es-do'); 

const cache_version = '0.5'; // Cambiar tambien en public/version.txt
setInterval(function(){ // Verifica si la version actual es la version de los archivos js
    let uuid = Math.random();
    jQuery.get('./version.txt',{id:uuid},function(data){
        if(data !== cache_version) window.location.reload(); // Si no es igual actualiza la pagina
    });
}, 15000);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Env>
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />}/>
                <Route path="/recovery" element={<Recovery />}/>
                <Route exact path="/dashboard" element={<Dashboard />}/>
                <Route exact path="/dashboard/:id" element={<Dashboard />}/>
                <Route exact path="/account" element={<Account />}/>
                <Route path="/historymap/:id/:id2" element={<HistoryMap />}/>
                {/* <Route exact path="/bills" component={Bills}/> */}
                {/* <Route path="/bills/:id" component={Bills}/> */}
                {/* <Route path="/support" component={Support}/> */}
                <Route exact path="/vehicle/:id" element={<Vehicle />}/>
                {/* <Route path="/records" component={AccountLog}/> */}
                {/* <Route path="/reports" component={Reports}/> */}
                <Route exact path="/notifications" element={<Notifications />}/>
                {/* <Route path="/config" component={Config}/> */}
                <Route path="/alerts/:id" element={<Alerts />}/>
                <Route path="/reports/:id" element={<Reports />}/>
                <Route exact path="/globalmap" element={<GlobalMap />}/>
                <Route exact path="/zonas" element={<Zonas />}/>
            </Routes>
        </Router>
        <Preload />
    </Env>
);
import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import InfolistEle from '../controls/infolistele.jsx';
// import { Link } from 'react-router-dom';
import Loading from '../controls/loading.jsx';
import Alert from '../controls/alert.jsx';
import Dropmenu from '../controls/dropmenu.jsx';
import Box from '../controls/box.jsx';
import Confirm from '../controls/confirm.jsx';
import ChangePassword from '../views/pop_changepassword.jsx';
import ChangeName from '../views/pop_changename.jsx';
import ChangePhone from '../views/pop_changephone.jsx';
import Menu from '../controls/menu.jsx';
import jQuery from 'jquery';
import Store from '../store';

class Account extends Component {

  constructor(){
    super();
    this.state = {
      infolistelearr:"",
      ajax1: '',
      ajax2: '',
      popwindow: '',
      menu: '',
      UsrNom: '',
      UsrTel: '',
      UsrMail: '',
      dropMenuData: [],
      dropMenuShow: false,
      nombreNuevo: '',
      nombreValido: false,
      telNuevo: '',
      telValido: false
    }
  }

  openClickMenu = () => {
    if(this.state.ui === 'desktop') return false;
		this.setState({menu:<Menu closeClickMenu={this.closeClickMenu} />});
	}
	
	closeClickMenu = () => {
		this.setState({menu:""});
	}

  componentDidMount(){
    this.cargarDatosCuenta();

    let gotoRecords = () => { this.props.history.push('/records'); }
    let gotoConfig = () => { this.props.history.push('/config'); }
    this.setState({
			dropMenuData:[
				{img:Icons['records'], title:"Historial", subtitle:'Registro de todo lo que hace tu cuenta', function:gotoRecords},
				{img:Icons['settings'], title:"Configuracion", subtitle:'Registro de todo lo que hace tu cuenta', function:gotoConfig},
				{img:Icons['exit'], title:"Salir", subtitle:'Cerrar sesión', function:this.clickSessionClose}
			]
    });
    
    Store.regListener("ui", 'account',this.onuiStoreChange);
    this.setState({ui: Store.getStore('ui')});
  }

  cargarDatosCuenta=()=>{
    var this0 = this;
    
    var ajax1 = jQuery.get("https://rema.hontrack.com/api/security/account", function(data){
      if(typeof data === "object"){

        let list = [];
        list.push( <InfolistEle key="x1" label="Nombre" title={data.account.UsrNom} /> );
        // list.push( <InfolistEle key="x1" label="Nombre" title={data.account.UsrNom} onClick={this0.editarNombre} ico={Icons['edit']} /> );
        // list.push( <InfolistEle key="x2" label="Teléfono" title={data.account.UsrTel} onClick={this0.editarTelefono} ico={Icons['edit']} /> );
        list.push( <InfolistEle key="x2" label="Teléfono" title={data.account.UsrTel} /> );
        list.push( <InfolistEle key="x3" label="Correo" title={data.account.UsrMail} /> );
        list.push( <InfolistEle key="x4" label="Contraseña" title="Cambiar" onClick={this0.editarPass} ico={Icons['edit']} /> );
        // list.push( <InfolistEle key="x5" label="Codigo de enlace"  title="x4g2lbz" /> );

        this0.setState({
          infolistelearr:list,
          popwindow: '',
          UsrNom: data.account.UsrNom,
          UsrTel: data.account.UsrTel,
          UsrMail: data.account.UsrMail
        });
        
      }else if(data === 'notlogin'){
        this0.props.history.push('/');
      }else{
        window.location.realod();
      }

    });

    this0.setState({
      ajax1: ajax1, 
      infolistelearr:<div className="nada"><Loading closefn tipo="texto" request={ajax1} /></div>
    });

  }

  onuiStoreChange=()=>{
		this.setState({ui: Store.getStore('ui')});
	}

  editarNombre=()=>{
    var this0 = this;
    
    let closefn = function(){
      this0.setState({popwindow: ''});
    }
    let closefn2 = function(){
      this0.setState({popwindow: ''});
      this0.cargarDatosCuenta();
    }
    let todobien = function(){
      this0.setState({
        popwindow: <Alert closefn={closefn2}><h3>Nombre actualizado</h3></Alert>,
        nombreValido: false
      });
    }

    let cambiarNombre = function(){
      if(!this0.state.nombreValido) return false;
      let name = this0.state.nombreNuevo;
      var ajax2 = jQuery.post('https://app.hontrack.com/apiphp/account.php', {md:'updatename', name:name}, function(data){
        this0.setState({popwindow:''});
        if(data === "ok"){
          todobien();
        }else if(data === 'notlogin'){
          this0.props.history.push('/');
        }else{
          alert('error');
        }
      });
  
      this0.setState({popwindow: <Loading close={()=>{this0.setState({popwindow:''} )}} request={ajax2} />});
    }

    let conte = <ChangeName onChange={this0.editandoNombre} closefn={closefn} />;
    this.setState({
      popwindow: <Confirm nofntext="Cancelar" yesfn={cambiarNombre} nofn={closefn} yesfntext="Cambiar">{conte}</Confirm>
    });
  }

  editandoNombre=(datos)=>{
    this.setState({
      nombreNuevo: datos.name,
      nombreValido: datos.esValido
    });
  }

  editarTelefono=()=>{
    var this0 = this;
    
    let todobien = function(){
      this0.setState({
        popwindow: <Alert closefn={closefn2}><h3>Teléfono actualizado</h3></Alert>,
        telValido: false
      });
    }

    let cambiarTel = function(){
      if(!this0.state.telValido) return false;
  
      let tel = this0.state.telNuevo;
      var ajax2 = jQuery.post('https://rema.hontrack.com/api/security/destroySess', {md:'updatetel', tel:tel}, function(data){
        this0.setState({popwindow:''});
        if(data === "ok"){
          todobien();
        }else if(data === 'notlogin'){
          this0.props.history.push('/');
        }else{
          alert('error');
        }
      });

      this0.setState({popwindow: <Loading close={()=>{this0.setState({popwindow:''} )}} request={ajax2} />});
  
    }

    let closefn = function(){
      this0.setState({popwindow: ''});
    }
    let closefn2 = function(){
      this0.setState({popwindow: ''});
      this0.cargarDatosCuenta();
    }
    let conte = <ChangePhone onChange={this0.editandoTel} closefn={closefn} />;
    this.setState({
      popwindow: <Confirm nofntext="Cancelar" yesfn={cambiarTel} nofn={closefn} yesfntext="Cambiar">{conte}</Confirm>
    });
  }

  editandoTel=(datos)=>{
    this.setState({
      telNuevo: datos.tel,
      telValido: datos.esValido
    });
  }

  editarPass=()=>{
    var this0 = this;
    
    let closefn = function(){
      this0.setState({popwindow: ''});
    }
    
    let todobien = function(){
      this0.setState({popwindow: <Alert closefn={closefn}><h3>Tu contraseña se ha cambiado.</h3></Alert>});
    }
    let passnomatch = function(){
      this0.setState({popwindow: <Alert closefn={closefn}><h3>Tu contraseña actual no es correcta.</h3></Alert>});
    }
    let conte = <ChangePassword passnomatch={passnomatch} success={todobien} closefn={closefn} />;
    this.setState({
      popwindow: <Box closefn={closefn}>{conte}</Box>
    });
  }
  
  componentWillUnmount(){
    if(this.state.ajax1) this.state.ajax1.abort();
    if(this.state.ajax2) this.state.ajax2.abort();
    Store.unregListener("ui", 'account');
  }

  toggleMenu=()=>{
		if(this.state.dropMenuShow){ this.setState({dropMenuShow: false}); }
		if(!this.state.dropMenuShow){ this.setState({dropMenuShow: true}); }
  }
  
  clickSessionClose=()=>{
    var this0 = this;
		jQuery.gete("https://rema.hontrack.com/api/security/destroySess", function(){
			this0.props.history.push('/');
		});
	}

  render() {
    return (
      <div className="app">
        {this.state.popwindow}
        {this.state.menu}
        <Dropmenu menuData={this.state.dropMenuData} closeMenu={()=>{this.setState({dropMenuShow:false})}} showstate={this.state.dropMenuShow} />
        <div className="appbar">
          <div className="appbar-title">Cuenta</div>
          <div onClick={this.openClickMenu} className="appbar-b1"><img alt="" src={Icons['menu']} /></div>
          <div className="appbar-b2"><img src={Icons['logo']} alt="" /></div>
          {/* <div onClick={this.toggleMenu} className="appbar-b3">
						<img alt="" src={Icons['more']} />
					</div> */}
        </div>
        <div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon"}>
          {this.state.ui === "desktop" && <Menu />}
          {/* {this.state.ui === "desktop" && <Menuseldev linktipo="t1" data={this.state.devDataList} />} */}
          <div className="appcon-pdd">
            <div className="appcon-header">
							<h1>Cuenta</h1>
							<span>Información general de tu cuenta.</span>
						</div>
            <div className="infolist">
              {this.state.infolistelearr}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Icons from '../controls/svgicons';
import jQuery from 'jquery';
import Store from '../store';

class Menu extends Component {
	constructor(){
		super();
		this.state = {
			id1: ''
		};
	}
    
    handleInnerClickMenu = (e) => {
		e.stopPropagation();
	}

    clickSessionClose = () => {
		jQuery.get("https://rema.hontrack.com/api/security/destroySess", ()=>{
            Store.setStore('isLoggin', false);
            window.location = '#/';
		});
	}

	componentDidMount(){
        if(this.props.id1) this.setState({id1: this.props.id1});
	}
    
    UNSAFE_componentWillReceiveProps(props){
        if(props.id1) this.setState({id1: props.id1});
    }
    
    componentWillUnmount(){
    }

	render() {
		return (
            <div onClick={this.props.closeClickMenu} className="envmenu">
                <div onClick={this.handleInnerClickMenu} className="menu">
                    <div className="menu-header">
                    </div>
                    <div className="menu-wraplist">
                        {/* <NavLink activeClassName="active" className="iconlink" to="/notifications" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['noti0']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Notificaciones</b>
                                <span>Ver historial de notificaciones</span>
                            </div>
                        </NavLink> */}
                        {/* <NavLink activeClassName="active" className="iconlink" to="/zonas" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['zonas']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Zonas</b>
                                <span>Establece áreas importantes.</span>
                            </div>
                        </NavLink> */}
                        {/* <NavLink activeClassName="active" className="iconlink" to="/globalmap" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['locations']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Mapa Global</b>
                                <span>Ver todos los vehículos</span>
                            </div>
                        </NavLink> */}
                        <NavLink activeClassName="active" className="iconlink" to={"/dashboard/"+this.state.id1} >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['dashboard']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Panel de control</b>
                                <span>Controla tu vehículo.</span>
                            </div>
                        </NavLink>
                        <NavLink activeClassName="active" className="iconlink" to="/account" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['account']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Cuenta</b>
                                <span>Información general</span>
                            </div>
                        </NavLink>
                        {/* <NavLink activeClassName="active" className="iconlink" to="/bills" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['balance']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Facturas</b>
                                <span>Información de pagos</span>
                            </div>
                        </NavLink> */}
                        {/* <NavLink activeClassName="active" className="iconlink" to="/support" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['help']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Soporte</b>
                                <span>Ayuda, información y retroalimentación.</span>
                            </div>
                        </NavLink> */}
                        {/* <NavLink activeClassName="active" className="iconlink" to="/config" >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['settings']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Configuración</b>
                                <span>Modifica tus preferencias.</span>
                            </div>
                        </NavLink> */}
                        <div className="iconlink" onClick={this.clickSessionClose} >
                            <div className="iconlink-svg">
                                <img alt="" src={Icons['exit']} />
                            </div>
                            <div className="iconlink-text">
                                <b>Salir</b>
                                <span>Cerrar sesión</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

export default Menu;

import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import { Link, useParams } from 'react-router-dom';
import jQuery from 'jquery';
import Slider from 'rc-slider';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Alert from '../controls/alert.jsx';
import 'rc-slider/assets/index.css';
import Store from '../store';
import Menuseldev from '../controls/menuseldev.jsx';
import Menu from '../controls/menu.jsx';
import {Marker, InfoWindow} from 'google-maps-react';
import GoogleMap from '../controls/mapcontainer.jsx';
import Confirm from '../controls/confirm.jsx';

class HistoryMap extends Component {

    constructor(props) {
        super();
        this.state = {
            deviceId: props.params.id,
            DevCod: props.params.id2,
            devDataActual: false,

            map: false,
            mapProps: false,
            google: false,
            
            centerMap: {lat: 0, lng: 0},
            allPins: false,
            actualPin: '',
            zoom: 14,

            minutosDeStops: 5,
            velocidadDeStops: 60,

            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},

            calendarOpen: true,
            calSelectedDay: new Date(),
            data: [],
            actualLat: 0,
            actualLng: 0,
            actualIndex: 0,
            maxIndex: 0,
            marker: [],
            barraPlayS: {'display': 'none'},
            popWindow: '',
            cargandoDatos: false,
            playing: false,
            playVelocity: 1,
            handleSlider: '',
            actualIndexForma: false,
            titleDevice: 'Cargando...',
            DevNum: '',
            ajax1: '',
            myTimeOut: false,

            kmsRecorridos: '',
            kmsPromedio: '',
            kmsVelicidadMax: '',
            inicioActividad: '',
            tiempoDetenido: '',
            tiempoMoviendose: '',
            finActividad: '',
            vecesDetenido: 0,

            stopsPins: [],
            allStops: [],
            allPinSpeed: []
        }
    }

    UNSAFE_componentWillMount(){
        Store.regListener("ui", 'historymap',this.onuiStoreChange);
		Store.regListener("devicesAll", 'historymap',this.onDevicesAllStoreChange);
        Store.regListener("isLoggin", 'historymap',this.onisLogginChange);
    }

    componentDidMount() {
        this.onuiStoreChange();
		this.onDevicesAllStoreChange();
		this.onisLogginChange();
    }

    UNSAFE_componentWillReceiveProps(props){
        this.setState({
            deviceId: props.params.id, 
            DevCod: props.params.id2,
            calendarOpen: true,
            data: [],
            allStops: [],
            allPinSpeed: [],
            allPins: []
        }, ()=>{
            this.onDevicesAllStoreChange();
        });
    }

    onuiStoreChange=()=>{
		this.setState({ui: Store.getStore('ui')});
    }
    
    onDevicesAllStoreChange=()=>{
        if(Store.getStore("devicesAll")){
            var data = Store.getStore("devicesAll");
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const element = data[key];
                    if(element.DevCod === this.state.DevCod){
                        if(JSON.stringify(element) !== JSON.stringify(this.state.devDataActual)){
                            this.setState({devDataActual: element});
                        }
                    }
                }
            }
        }
    }
    
    onisLogginChange=()=>{
        if(!Store.getStore('isLoggin')){
            window.location = '/';
        }
	}

    componentWillUnmount(){
        if(this.state.ajax1) this.state.ajax1.abort();
        Store.unregListener("ui", 'historymap');
        Store.unregListener("devicesAll", 'historymap');
        Store.unregListener("isLoggin", 'historymap');
    }

    cargarDatos = () => {
        var this0 = this;
        var deviceId = this.state.deviceId;
        var VehPla = this0.state.devDataActual.VehPla;
        var fechaReq = moment(this.state.calSelectedDay).format("YYYY-MM-DD");
        if(deviceId && !this.state.cargandoDatos){
            this0.setState({cargandoDatos: true, barraPlayS: {'display': 'block'}});
            jQuery.get('https://rema.hontrack.com/api/security/history', {
                VehPla: VehPla,
                fecha: fechaReq
            }, function (data) {
                console.log(data);
                if(typeof data === 'object'){
                    if(data.history?.length){
                        let actualIndexForma = moment(data.history[0].HisDFch).subtract(0,'hours').format('LTS');
                        this0.setState({
                            zoomMap: 15,
                            actualPin: '',
                            playing: false,
                            actualIndex: 0,
                            allPins: false,
                            calendarOpen: false,
                            data:data.history,
                            dataEvents:data.events,
                            cargandoDatos: false,
                            maxIndex: data.history.length-1,
                            actualIndexForma: actualIndexForma,
                        },function(){
                            this0.cargarDistanciaenKM();
                            this0.cargarStats();
                        });
                    }else{
                        this0.setState({
                            cargandoDatos: false,
                        });
                    }
                    
                }else{
                    let closefn = function(){ 
                        this0.setState({
                            zoomMap: 5,
                            popWindow:'',
                            barraPlayS: {'display': 'none'},
                            actualPin: '',
                            cargandoDatos: false
                        }); 
                    }
                    let alert = (
                    <Alert closefn={closefn}>
                        <center>
                            <img className="bigicon" src={Icons['error']} alt=""/>
                            <h3>No hay datos que cargar, escoge otra fecha.</h3>
                        </center>
                    </Alert>
                    );
                    this0.setState({popWindow:alert, stopsPins: [], actualIndex:0, actualIndexForma: false});
                }
            });
        }
        
    }

    stopHistory=()=>{
        this.setState({
            playing: false
        },()=>{
            this.setVehicleMap(0);
        });
    }

    pauseHistory=()=>{
        this.setState({
            playing: false
        });
    }

    cargarStats=()=>{
        var stopsPins = [];
        var data = this.state.data;
        // console.log(data);
        var kmsacums = 0;
        var cantidadkmsacums = 0;
        var velmax = 0;
        var cantdetenido = 0;
        var minsdeteacum = 0;

        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                // Paradas
                let elementA = data[key];
                let elementB = data[parseInt(key,10)+1] && data[parseInt(key,10)+1];

                if(parseInt(key,10) === 0){
                    // Sacamos la diferencia entre el primer registro y el inicio del dia
                    // console.log(moment(elementA.HisDFch).format("YYYY-MM-DD")+" 00:00:00.000000");
                    let dateini = moment(moment(elementA.HisDFch).format("YYYY-MM-DD")+" 00:00:00.000000");
                    let diff = moment(moment(elementA.HisDFch).subtract(0,'hours').format()).diff(dateini, 'minutes');
                    // console.log("dif entre primer pin e inicio: "+diff);
                    if(diff > 1){
                        cantdetenido++;
                        minsdeteacum = minsdeteacum + diff;
                        // let tiemdeteformat = "por "+diff+"m";
                        // if(diff > 60) tiemdeteformat = "por "+(diff/60).toFixed(1)+"h";
                        // stopsPins.push(<Mapplacestop fecha={tiemdeteformat} lat={elementA.HisDLat} lng={elementA.HisDLog} key={key} />);
                    }
                }

                if(elementB){
                    let diff = moment(elementB.HisDFch).diff(moment(elementA.HisDFch), 'minutes');
                    if(diff > 2){
                        cantdetenido++;
                        minsdeteacum = minsdeteacum + diff;
                        // if(diff > 60) tiemdeteformat = "por "+(diff/60).toFixed(1)+"h";
                        // stopsPins.push(<Mapplacestop fecha={tiemdeteformat} zIndex={zindex} lat={elementB.HisDLat} lng={elementB.HisDLog} key={key} />);
                    }
                }

                if(parseInt(key,10) === (data.length-1)){
                    // Sacamos la diferencia entre el ultimo registro y el fin del dia
                    // console.log(moment(moment(elementA.HisDFch).subtract(0,'hours').format()).format("YYYY-MM-DD")+" 23:59:59.000000");
                    // console.log(moment(elementA.HisDFch).subtract(0,'hours').format());
                    let dateini = moment(elementA.HisDFch).subtract(0,'hours').format();
                    let datefin = moment(moment(moment(elementA.HisDFch).subtract(0,'hours').format()).format("YYYY-MM-DD")+" 23:59:59.000000");
                    let diff = moment(datefin).diff(dateini, 'minutes');
                    // console.log("dif entre primer pin e fin: "+diff);
                    if(diff > 1){
                        cantdetenido++;
                        minsdeteacum = minsdeteacum + diff;
                        // let tiemdeteformat = "por "+diff+"m";
                        // if(diff > 60) tiemdeteformat = "por "+(diff/60).toFixed(1)+"h";
                        // stopsPins.push(<Mapplacestop fecha={tiemdeteformat} lat={elementA.HisDLat} lng={elementA.HisDLog} key={key} />);
                    }
                }
                
                // Velocidad promedio
                if(elementA.HisDSpd > 20){
                    kmsacums = kmsacums + parseInt(elementA.HisDSpd, 10);
                    cantidadkmsacums++;
                }

                if(parseInt(elementA.HisDSpd, 10) > velmax){
                    velmax = parseInt(elementA.HisDSpd, 10);
                }
            }
        }

        // Velocidad promedio
        let kmsPromedio = (kmsacums / cantidadkmsacums).toFixed(1) + "km/h";
        let kmsVelicidadMax = velmax + "km/h";

        // Primer registro del dia
        let inicioActividad = moment(this.state.data[0].HisDFch).subtract(0,'hours').format('LTS');

        // Ultimo registro
        let finActividad = moment(this.state.data[this.state.data.length-1].HisDFch).subtract(0,'hours').format('LTS');

        // Tiempo detenido
        let tiemdeteformat = minsdeteacum+"m";
        if(minsdeteacum > 60) tiemdeteformat = (minsdeteacum/60).toFixed(1)+"h";

        // Sacando tiempo actividad
        let tiempoMoviendose = 1440 - minsdeteacum;
        let tiempoMoviendoseFormate = tiempoMoviendose+"m";
        if(tiempoMoviendose > 60) tiempoMoviendoseFormate = (tiempoMoviendose/60).toFixed(1)+"h";
        
        this.setState({
            stopsPins: stopsPins, 
            kmsPromedio: kmsPromedio, 
            kmsVelicidadMax: kmsVelicidadMax, 
            inicioActividad:inicioActividad, 
            finActividad:finActividad,
            vecesDetenido: cantdetenido,
            tiempoDetenido: tiemdeteformat,
            tiempoMoviendose: tiempoMoviendoseFormate
        });
    }

    onMarkerClick = (props, marker, e) =>{
        this.setState({
          selectedPlace: props,
          activeMarker: marker,
          showingInfoWindow: true
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
            showingInfoWindow: false,
            activeMarker: null
            })
        }
    }

    setVehicleMap=(index)=>{
        var this0 = this;
        let titleLabel = moment(this0.state.data[index].HisDFch).subtract(0,'hours').format('LTS');
        let lat = parseFloat(this0.state.data[index].HisDLat) || 0;
        let lng = parseFloat(this0.state.data[index].HisDLog) || 0;
        let name = (
            <div>
                <div>{titleLabel}</div>
                <div>{this0.state.data[index].HisDSpd}km/h</div>
            </div>
        )
        let pin = 
            <Marker 
                onClick={this.onMarkerClick}
                icon={{
                    url: Icons['car'],
                    anchor: new this.state.google.maps.Point(30,60),
                    scaledSize: new this.state.google.maps.Size(60,60)
                }} 
                name={name}
                zIndex={999}
                position={{lat: this0.state.data[index].HisDLat, lng: this0.state.data[index].HisDLog}} 
            />
        ;

        this0.setState({
            actualPin: pin,
            centerMap: {lat: lat, lng: lng},
            actualLat: this0.state.data[index].HisDLat,
            actualLng: this0.state.data[index].HisDLog,
            actualIndexForma: titleLabel,
            actualIndex: index
        });
    }

    playHistory2=()=>{
        this.setState({
            zoomMap: 15,
            playing: true
        },()=>{

            this.setVehicleMap(this.state.actualIndex);

            var myTimeOut = setTimeout(()=>{
                if(!this.state.playing) return false;
                if(this.state.actualIndex+1 > this.state.maxIndex){
                    // Llego al final
                    this.setState({playing: false});
                }else{
                    this.setVehicleMap(this.state.actualIndex+1);
                    this.playHistory2();
                }
            }, 1000/this.state.playVelocity);

            this.setState({myTimeOut:myTimeOut});

        });

        
    }

    playerChangeSpeed=()=>{
        if(this.state.playVelocity === 1){
            this.setState({playVelocity: 2});
        }else{
            this.setState({playVelocity: 1});
        }
    }

    changeSlider=(v)=>{
        clearTimeout(this.state.myTimeOut);
        if(v>this.state.maxIndex) return false;
        this.setVehicleMap(v);
    }

    beforeChangeSlider=()=>{
        this.setState({
            playing: false
        });
    }

    cargarDistanciaenKM=()=>{
        var kms = getDistance(this.state.data);
        // console.log("La distancia recorrida es: "+kms+" kms");
        this.setState({kmsRecorridos: kms+" kms"})
        function getDistance(data){
            var distance = 0;
        
            if(data.length > 0){
                var locationPrev = data[0];
                
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        if(key > 0)locationPrev = data[key-1];
                        let locationActu = data[key];
                        let kmsItera = calcCrow(locationPrev.HisDLat,locationPrev.HisDLog,locationActu.HisDLat,locationActu.HisDLog);
                        if(kmsItera > 0.1) distance += kmsItera;
                    }
                }
            }
        
            return decimalAdjust('round', distance, -2);
        }
        
        function calcCrow(lat1, lon1, lat2, lon2){
          var R = 6371; // km
          var dLat = toRad(lat2-lat1);
          var dLon = toRad(lon2-lon1);
          lat1 = toRad(lat1);
          lat2 = toRad(lat2);
        
          var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
          var d = R * c;
          return d;
        }
        
        // Converts numeric degrees to radians
        function toRad(Value) 
        {
            return Value * Math.PI / 180;
        }
        
        function decimalAdjust(type, value, exp) {
            // Si el exp no está definido o es cero...
            if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
            }
            value = +value;
            exp = +exp;
            // Si el valor no es un número o el exp no es un entero...
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
            }
            // Shift
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            // Shift back
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }
        
    }

    infoStats=()=>{
        let closefn = ()=>{ this.setState({popWindow:''}); }
        let alert = (
        <Alert closefn={closefn}>
            <div className="historyStatConBox">
                <h1>
                    <div>Estadísticas</div>
                    <span>{this.state.devDataActual.DevDesc}</span>
                    <span>{moment(this.state.calSelectedDay).format("LL")}</span>
                </h1>
                <div className="historyStatConBox-stats">
                    <div className="historyStatConBox-stats-ele">
                        <div>Inicio de actividad</div>
                        <span>{this.state.inicioActividad}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Kilómetros recorridos</div>
                        <span>{this.state.kmsRecorridos}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Velocidad promedio</div>
                        <span>{this.state.kmsPromedio}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Velocidad máxima</div>
                        <span>{this.state.kmsVelicidadMax}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Veces detenido</div>
                        <span>{this.state.vecesDetenido}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Tiempo detenido</div>
                        <span>{this.state.tiempoDetenido}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Tiempo moviendose</div>
                        <span>{this.state.tiempoMoviendose}</span>
                    </div>
                    <div className="historyStatConBox-stats-ele">
                        <div>Fin de actividad</div>
                        <span>{this.state.finActividad}</span>
                    </div>
                </div>
            </div>
        </Alert>
        );
        this.setState({popWindow:alert});
    }

    toggleMostrarCalendario=()=>{
        if(!this.state.data.length) return false;
        console.log(this.state.data);
        this.setState({calendarOpen:!this.state.calendarOpen},()=>{
            if(this.state.calendarOpen){
                this.pauseHistory();
            }
        });
        
    }

    selCalDia=(day)=>{
        this.setState({ calSelectedDay: day });
        this.pauseHistory();
    }

    onReadyMap=(mapProps, map, google)=>{
		console.log('onReadyMap');
		if(localStorage.getItem("h_MapTypeId")) map.setMapTypeId(localStorage.getItem("h_MapTypeId")); // Pone el tema que esta en el storage
		this.setState({readyMapState: true, map: map, mapProps: mapProps, google:google}, ()=>{
            this.setVehicleMap(0);
        });

        console.log(this.state.data);

        var puntos = [];
        for (const key in this.state.data) {
            if (this.state.data.hasOwnProperty(key)) {
                const element = this.state.data[key];
                puntos.push({lat: parseFloat(element.HisDLat), lng: parseFloat(element.HisDLog)});
            }
        }

          var flightPath = new google.maps.Polyline({
            path: puntos,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2
          });
  
          flightPath.setMap(map);
        
	}

    renderBarraSlider=()=>{
        return (
            <div style={this.state.barraPlayS} className="barraHistoryPlayer">
                {this.state.cargandoDatos ? (
                    <div className="barraHistoryPlayer-loading">Cargando...</div>
                ):(
                <div className="pdd">
                    {this.state.playing ? (
                    <div className="barraHistoryPlayer-playpause" onClick={this.pauseHistory}>
                        <img title="Pausar reproducción" src={Icons['pause']} alt=""/>
                    </div>
                    ) : (
                    <div className="barraHistoryPlayer-playpause" onClick={this.playHistory2}>
                        <img title="Reproducir historial" src={Icons['play']} alt=""/>
                    </div>
                    )}
                    <div className="barraHistoryPlayer-slider">
                        <Slider 
                            onAfterChange={this.AfterChangeSlider} 
                            onBeforeChange={this.beforeChangeSlider} 
                            onChange={this.changeSlider} 
                            min={0}
                            value={parseInt(this.state.actualIndex, 10)}
                            max={this.state.data.length} 
                            />
                    </div>
                    <div onClick={this.playerChangeSpeed} className="barraHistoryPlayer-speed">{"x"+this.state.playVelocity}</div>
                    <div onClick={this.stopHistory} className="barraHistoryPlayer-stop">
                        <img src={Icons['stop']} alt=""/>
                    </div>
                </div>
                )}
            </div>
        )
    }

    renderPantallaSeleccionarFecha=()=>{
        return (
            <div className="viewCalendarHistory">
                <div className="viewCalendarHistory-title">
                    <h1>
                        <div>{this.state.devDataActual.DevDesc}</div>
                        <span>Selecciona una fecha</span>
                    </h1>
                </div>
                <div className="viewCalendarHistory-conteCal">
                    <DayPicker
                        months={['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']}
                        weekdaysLong={['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado']}
                        weekdaysShort={['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']}
                        onDayClick={this.selCalDia}
                        selectedDays={this.state.calSelectedDay}
                        locale="es-do"
                    />
                </div>
                {this.state.cargandoDatos && <div className="viewCalendarHistory-loading">Cargando....</div>}
                {this.state.calSelectedDay && <button onClick={this.cargarDatos}>Cargar fecha {this.state.calSelectedDay.toLocaleDateString()}</button>}
            </div>
        )
    }

    renderTituloMapa=()=>{
        console.log(this.state.devDataActualDevLstSpd);
        return (
            <div className="barraHistoryPrincipal">
                <div className="pdd">
                    <div className="barraHistoryPrincipal-name">
                        <div>{this.state.devDataActual.DevDesc}</div>
                        {this.state.actualIndexForma && <span>{moment(this.state.calSelectedDay).format("MMMM DD")} - <b>{this.state.actualIndexForma}, {this.state.data[this.state.actualIndex].HisDSpd}km/h</b></span>}
                    </div>
                </div>
            </div>
        )
    }

    toggleMostrarTodosLosPuntos=()=>{
        this.setState({allPins:false});

        var mostrarTodosPuntos=()=>{
            console.log('Mostrar puntos');
            let allPins = [];
            for (const key in this.state.data) {
                if (this.state.data.hasOwnProperty(key)) {
                    const element = this.state.data[key];
                    // console.log(element);
                    allPins.push(
                        <Marker 
                            key={Math.random().toString(36).substr(2, 9)}
                            onClick={()=>{this.pauseHistory(); this.setVehicleMap(parseInt(key, 10));}}
                            icon={{
                                url: Icons['pinmap2'],
                                anchor: new this.state.google.maps.Point(15,15),
                                scaledSize: new this.state.google.maps.Size(30,30)
                            }} 
                            name={element.HisDFch} 
                            position={{lat: element.HisDLat, lng: element.HisDLog}} 
                        />
                    );
                }
            }
            this.setState({allPins:allPins, popWindow: false});
        }

        this.setState({popWindow:
            <Confirm 
                nofntext="Cancelar" 
                yesfn={mostrarTodosPuntos} 
                nofn={()=>{this.setState({popWindow:false})}} 
                yesfntext="Mostrar">
                <h1>Mostrar recorrido</h1>
                <p>Esta opción muestra todos puntos donde el vehículo estuvo, recuerda presionar los círculos rojos que aparecerán en el mapa para obtener información adicional.</p>
            </Confirm>
        })
    }

    onBoundsChangedMap=(mapProps, map)=>{
		// Cada vez que el mapa se actualice tambien actualizo el nivel de zoom
		// Para evitar bugs con los controles manuales de zoom
		this.setState({zoom:map.getZoom()}); 
	}

    toggleMostrarTodosLosStops=()=>{
        
        if(this.state.popWindowFixSliderActive){
            this.setState({popWindowFixSliderActive:false});
        }else{
            this.setState({popWindowFixSliderActive:true, allStops:[]});
        }
    }

    togglePuntosVelocidad=()=>{
        
        if(this.state.popWindowFixSlider2Active){
            this.setState({popWindowFixSlider2Active:false});
        }else{
            this.setState({popWindowFixSlider2Active:true, allPinSpeed:[]});
        }
    }

    configurarPinsStops=()=>{
        var data = this.state.data;
        var activarStops = ()=>{
            let allStops = [];
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    // Paradas
                    let elementA = data[key];
                    let elementB = data[parseInt(key,10)+1] && data[parseInt(key,10)+1];
    
                    if(elementB){
                        let diff = moment(elementB.HisDFch).diff(moment(elementA.HisDFch), 'minutes');
                        let timeFormat = moment(elementA.HisDFch).subtract(0,'hours').format('LTS');
                        if(diff > this.state.minutosDeStops){
                            let markerName = <div>
                                <div>
                                    <div>Detenido por {diff} Minutos</div>
                                    <div>a las {timeFormat}</div>
                                </div>
                                </div>;
                            allStops.push(
                                <Marker 
                                    key={Math.random().toString(36).substr(2, 9)}
                                    onClick={this.onMarkerClick}
                                    icon={{
                                        url: Icons['flagpin'],
                                        anchor: new this.state.google.maps.Point(15,30),
                                        scaledSize: new this.state.google.maps.Size(30,30)
                                    }} 
                                    name={markerName} 
                                    position={{lat: elementB.HisDLat, lng: elementB.HisDLog}} 
                                />
                            );
                        }
                    }
    
                    if(parseInt(key,10) === (data.length-1)){
                        // Sacamos la diferencia entre el ultimo registro y el fin del dia
                        let dateini = moment(elementA.HisDFch).subtract(0,'hours').format();
                        let datefin = moment(moment(elementA.HisDFch).format("YYYY-MM-DD")+" 23:59:59.000000");
                        let diff = moment(datefin).diff(dateini, 'minutes');
                        let timeFormat = moment(elementA.HisDFch).subtract(0,'hours').format('LTS');
                        if(diff > this.state.minutosDeStops){
                            let markerName = <div>
                                <div>
                                    <div>Detenido a las {timeFormat}</div>
                                    <div>y fue la última ubicación del día.</div>
                                </div>
                                </div>;
                            allStops.push(
                                <Marker 
                                    key={Math.random().toString(36).substr(2, 9)}
                                    onClick={this.onMarkerClick}
                                    icon={{
                                        url: Icons['flagpin'],
                                        anchor: new this.state.google.maps.Point(15,15),
                                        scaledSize: new this.state.google.maps.Size(30,30)
                                    }} 
                                    name={markerName} 
                                    position={{lat: elementA.HisDLat, lng: elementA.HisDLog}} 
                                />
                            );
                        }
                    }
                    
                }
            }
            this.setState({allStops:allStops, popWindowFixSliderActive:false});
        }
        return (
            <Confirm 
                nofntext="Cancelar" 
                yesfn={activarStops} 
                nofn={()=>{this.setState({popWindowFixSliderActive:false})}} 
                yesfntext="Guardar">
                <h1>Mostrar altos</h1>
                <p>Esta opción muestra los puntos donde el vehículo se detuvo según los minutos que estén configurados, recuerda presionar las banderas que aparecerán en el mapa para obtener información adicional.</p>
                <p>Configurar en mayores de: <b>{this.state.minutosDeStops}</b> minutos</p>
                <div style={{padding:"0 2em"}}>
                    <Slider 
                        onAfterChange={(value)=>{console.log(value)}} 
                        onChange={(value)=>{this.setState({minutosDeStops: value})}} 
                        min={5}
                        step={1}
                        value={this.state.minutosDeStops}
                        max={60} 
                        />
                </div>
            </Confirm>
        );
    }

    configurarPinsVelocidad=()=>{
        var data = this.state.data;
        var activarPinsVelocidad = ()=>{
            let allPinSpeed = [];
            for (var key in data) {
                if (data.hasOwnProperty(key)){
                    let element = data[key];
                    if(element.HisDSpd > this.state.velocidadDeStops){
                        let timeFormat = moment(element.HisDFch).subtract(0,'hours').format('LTS');
                        let markerName = <div>
                            <div>
                                <div>Velocidad: {element.HisDSpd}km/h</div>
                                <div>Hora: {timeFormat}</div>
                            </div>
                            </div>;
                        allPinSpeed.push(
                            <Marker 
                                key={Math.random().toString(36).substr(2, 9)}
                                onClick={this.onMarkerClick}
                                icon={{
                                    url: Icons['speed'],
                                    anchor: new this.state.google.maps.Point(15,15),
                                    scaledSize: new this.state.google.maps.Size(30,30)
                                }} 
                                name={markerName} 
                                position={{lat: element.HisDLat, lng: element.HisDLog}} 
                            />
                        );
                    }    
                }
            }
            this.setState({allPinSpeed:allPinSpeed, popWindowFixSlider2Active:false});
        }
        return (
            <Confirm 
                nofntext="Cancelar" 
                yesfn={activarPinsVelocidad} 
                nofn={()=>{this.setState({popWindowFixSlider2Active:false})}} 
                yesfntext="Guardar">
                <h1>Mostrar excesos</h1>
                <p>Esta opción muestra todos los puntos en el mapa donde el vehículo rebaso la velocidad configurada abajo, recuerda presionar las marcas que aparecerán en el mapa para obtener información adicional.</p>
                <p>Configurar en mayor de: <b>{this.state.velocidadDeStops}</b> km/h</p>
                <div style={{padding:"0 2em"}}>
                    <Slider 
                        onAfterChange={(value)=>{console.log(value)}} 
                        onChange={(value)=>{this.setState({velocidadDeStops: value})}} 
                        min={60}
                        step={5}
                        value={this.state.velocidadDeStops}
                        max={200} 
                        />
                </div>
            </Confirm>
        );
    }

    render() {
        return (
            <div className="app">
                {this.state.popWindow}
                {this.state.popWindowFixSliderActive && this.configurarPinsStops()}
                {this.state.popWindowFixSlider2Active && this.configurarPinsVelocidad()}
                <div className="appbar">
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-title">Historial de ubicaciones</Link>
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-b1"><img alt="" src={Icons['back']}/></Link>
                    <Link to={"/dashboard/"+this.state.DevCod} className="appbar-b2"><img alt="" src={Icons['logo']}/></Link>
                    <div onClick={this.toggleMostrarCalendario} title="Seleccionar fecha" className="appbar-b3"><img alt="" src={Icons['cale2']} /></div>
                </div>
                <div className={this.state.ui === "desktop" ? "appcon uidesktop" : "appcon full"}>
                    {this.state.ui === "desktop" && <Menu id1={this.state.DevCod} />}
					{this.state.ui === "desktop" && <Menuseldev linktipo="t3" />}
                    {this.state.calendarOpen && this.renderPantallaSeleccionarFecha() }
                    {this.state.calendarOpen || 
                    <div className="appcon-mapa mapahistorial">
                        {this.renderTituloMapa()}
                        <GoogleMap onClick={this.onMapClicked} zoom={this.state.zoom} center={this.state.centerMap} onReady={this.onReadyMap} onBoundsChangedMap={this.onBoundsChangedMap}>
                            {this.state.actualPin}
                            {this.state.allPins}
                            {this.state.allStops}
                            {this.state.allPinSpeed}
                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}
                                onClose={()=>{this.setState({showingInfoWindow:false})}}
                                >
                                    <div>{this.state.selectedPlace.name}</div>
                                </InfoWindow>
						</GoogleMap>
                        <ControllersMap context={this} />
                        {this.renderBarraSlider()}
                        <div onClick={this.toggleMostrarTodosLosStops} className="btEstiloCompartido bpPuntosStops"><img src={Icons['flag']} alt=""/></div>
                        <div onClick={this.toggleMostrarTodosLosPuntos} className="btEstiloCompartido btHistorialPuntos"><img src={Icons['linesh']} alt=""/></div>
                        <div onClick={this.infoStats} className="btEstiloCompartido btStats"><img src={Icons['stats']} alt=""/></div>
                        <div onClick={this.togglePuntosVelocidad} className="btEstiloCompartido btSpeedPin"><img src={Icons['speed2']} alt=""/></div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

class ControllersMap extends Component {
	cambiarLayer=()=>{
		// Cambia la vista del terreno
		if(this.props.context.state.map.mapTypeId === 'hybrid'){
			this.props.context.state.map.setMapTypeId('roadmap');
			localStorage.setItem("h_MapTypeId", "roadmap");
		}else if(this.props.context.state.map.mapTypeId === 'roadmap'){
			this.props.context.state.map.setMapTypeId('noche');
			localStorage.setItem("h_MapTypeId", "noche");
		}else{
			this.props.context.state.map.setMapTypeId('hybrid');
			localStorage.setItem("h_MapTypeId", "hybrid");
		}
	}

	aumentarZoom=()=>{
		if(this.props.context.state.zoom < 19){
			this.props.context.setState({zoom: this.props.context.state.zoom+1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
			
		}
	}
	
	reducirZoom=()=>{
		if(this.props.context.state.zoom > 2){
			this.props.context.setState({zoom: this.props.context.state.zoom-1});
			// console.log(this.props.context.state.zoom);
			// console.log(this.props.context.state.map);
		}
	}

	centrarMapa=()=>{
		// Al parecer si center ya tiene las coordenadas aunque el mapa este en otro lugar no acutaliza center
		// Entonces lo que hare sera modificar coordenadas justo antes de reasignarlas al mismo valor eso arreglara todo creo
		this.props.context.setState({centerMap: {lat: 14, lng: -87}},()=>{
			this.props.context.setState({centerMap: {lat: this.props.context.state.actualLat, lng: this.props.context.state.actualLng}});
		});
	}

	render(){
		return(
			<div className="genmapctrlsv1 estilo2">
				<div onClick={this.reducirZoom} title="Alerjar" className="genmapctrlsv1-btn"><img src={Icons['zoom2']} alt=""/></div>
				<div onClick={this.centrarMapa} title="Centrar mapa" className="genmapctrlsv1-btn"><img src={Icons['center']} alt=""/></div>
				<div onClick={this.cambiarLayer} title="Cambiar modo de terreno" className="genmapctrlsv1-btn"><img src={Icons['layers']} alt=""/></div>
				<div onClick={this.aumentarZoom} title="Acercar" className="genmapctrlsv1-btn"><img src={Icons['zoom1']} alt=""/></div>
			</div>
		)
	}
}

let HistoryMapHook = () => {
	let params = useParams();
	return <HistoryMap params = {params}  />;
} 

export default HistoryMapHook;




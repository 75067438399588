import React, { Component } from 'react';
import Icons from '../controls/svgicons';
import Dropmenu from '../controls/dropmenu.jsx';
import Menu from '../controls/menu.jsx';
import jQuery from 'jquery';
import Store from '../store';
import moment from 'moment';

class Notifications extends Component {

  constructor(){
    super();
    this.state = {
      menu: '',
      cargando: false,
      dropMenuShow: false,
      ajax1: '',
      ajax2: '',
      popwindow: ''
    }
  }

  openClickMenu = () => {
      if(this.state.ui === 'desktop') return false;
  this.setState({menu:<Menu closeClickMenu={()=>{this.setState({menu:""})}} />});
  }   

  componentDidMount(){
      this.cargarDatosCuenta();
      
      Store.regListener("ui", 'notifications',this.onuiStoreChange);
      this.setState({ui: Store.getStore('ui')});
  }

  cargarDatosCuenta=()=>{
      this.setState({cargando: true});
      jQuery.post("https://app.hontrack.com/apiphp/events.php", {md:"get"}, (data)=>{ 
        this.setState({cargando: false});
        if(typeof data === 'object'){
          // console.log(data);
          this.setState({data: data.data.events});
        }
      });
  }

  onuiStoreChange=()=>{
      this.setState({ui: Store.getStore('ui')});
  }

  componentWillUnmount(){
      if(this.state.ajax1) this.state.ajax1.abort();
      if(this.state.ajax2) this.state.ajax2.abort();
      Store.unregListener("ui", 'notifications');
  }

  toggleMenu=()=>{
      if(this.state.dropMenuShow){ this.setState({dropMenuShow: false}); }
      if(!this.state.dropMenuShow){ this.setState({dropMenuShow: true}); }
  }

  descargarHistorial=()=>{
    alert('Esta característica está en desarrollo.');
  }

  render() {

    var NotisList = ()=>{
      let listDivs = [];
      for (const key in this.state.data) {
        if (this.state.data.hasOwnProperty(key)) {
          const element = this.state.data[key];
          let txtdesc = "Notificación desconocida ("+element.EvtTip+")";
          let txtfch = moment(element.EvtFch).subtract(0,'hours').fromNow();
          let imgico = 'noti0';
          
          if(element.EvtTip === "STOPENGINE"){
            txtdesc = <span>El vehículo <b>{element.DevDesc}</b> se ha apagado</span>;
          }

          if(element.EvtTip === "STARTMOVE"){
            txtdesc = <span>El vehículo <b>{element.DevDesc}</b> se ha encendido</span>;
          }

          if(element.EvtTip === "STOPMOVE"){
            txtdesc = <span>El vehículo <b>{element.DevDesc}</b> se ha detenido</span>;
          }

          if(element.EvtTip === "EMERGENCY"){
            txtdesc = <span>El vehículo <b>{element.DevDesc}</b> ha pedido ayuda</span>;
          }

          if(element.EvtTip === "POWER_CUT"){
            txtdesc = <span>El dispositivo en el vehículo <b>{element.DevDesc}</b> fue desconectado de la fuente de energía</span>;
          }

          if(element.EvtTip === "LOW_BAT"){
            txtdesc = <span>El dispositivo en el vehículo <b>{element.DevDesc}</b> tiene el nivel de batería muy baja</span>;
          }

          if(element.EvtTip === "SPEEDLIMIT"){
            txtdesc = <span>El vehículo <b>{element.DevDesc}</b> ha superado el límite de velocidad establecido</span>;
          }

          
          listDivs.push(
            <div key={element.EvtCod} className="notisList-ele">
              <div className="notisList-ele-txt">
                <div className="notisList-ele-txt-con">
                  <span className="notisList-ele-txt-con-img"><img src={Icons[imgico]} alt=""/></span>
                  <span className="notisList-ele-txt-con-txt">
                    <span>{txtdesc}, </span>
                    <span>{txtfch}.</span>
                  </span>
                </div>
              </div>
            </div>
          );
        }
      }
      return (
        <div className="notisList">
          {listDivs}
        </div>
      );
    }

    var Botones1 = () =>{
      return (
        <center style={{paddingTop: '2em'}} className="gbuttons">
          <div onClick={this.descargarHistorial} className="button"><span>Descargar historial</span></div>
        </center>
      );
    }

    return (
      <div className="app">
        {this.state.popwindow}
        {this.state.menu}
        <Dropmenu menuData={this.state.dropMenuData} closeMenu={()=>{this.setState({dropMenuShow:false})}} showstate={this.state.dropMenuShow} />
        <div className="appbar">
          <div className="appbar-title">Cuenta</div>
          <div onClick={this.openClickMenu} className="appbar-b1"><img alt="" src={Icons['menu']} /></div>
          <div className="appbar-b2"><img src={Icons['logo']} alt="" /></div>
        </div>
        <div className={this.state.ui === "desktop" ? "appcon uidesktop soloizq" : "appcon"}>
          {this.state.ui === "desktop" && <Menu />}
          <div className="appcon-scroll100">
            <div className="appcon-pdd">
              <div className="appcon-header">
                  <h1>Notificaciones</h1>
                  <span>Revisa el historial de notificaciones de la cuenta</span>
              </div>

              {this.state.cargando || <NotisList />}
              {this.state.cargando && <center>Cargando....</center>}
              {this.state.cargando || <Botones1 />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notifications;
